@import "global/s-variables";
@import "global/t-fn-math";
@import "global/t-fn-measurement";
@import "global/t-fn-color";
@import "global/t-fn-z-index";
@import "global/t-mx-clearfix";
@import "global/t-mx-media-queries";
@import "global/t-mx-grid-framework";
@import "global/t-mx-link-states";
@import "global/t-mx-font-size";
@import "global/g-fonts";

body {
    font-size: $base-font-size;
    font-family: $base-font-family;
    background-color: color('brown', 'default');
    background-image: url('../../dist/images/bg-login.jpg');
    background-repeat: repeat;
}

.login {
    font-family: $montserrat-light;
    h1 a {
        width: 100%;
        height: 180px;
        max-width: 321px;
        background-image: url('../../dist/images/logo.jpg');
        background-size: 100%;
        -webkit-box-shadow: 0px 16px 16px -16px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 16px 16px -16px rgba(0,0,0,0.75);
        box-shadow: 0px 16px 16px -16px rgba(0,0,0,0.75);
    }

    form {
        background-color: color('green', 'default');
        box-shadow: none;
    }

    label {
        font-size: em(14px);
        color: white;
    }

    input[type="text"],
    input[type="password"] {
        padding: rem(10px);
        font-size: em(16px, 14px);
        border: 0;
        box-shadow: none;
        appearance: none;
    }

    input[type="checkbox"] {
        color: $base-font-color;
        background-color: white;
        border: 0;
        box-shadow: none;

        &:checked:before {
            color: color('bigrigmedia', 'grey');
        }
    }

    .message {
        margin-bottom: rem(30px);
        padding: rem(halve($base-gutter));
        color: alert('info', 'message');
        background-color: alert('info');
        border: 1px solid alert('info', 'border');
    }

    #nav,
    #backtoblog {
        font-size: em(14px);
        color: white;

        a {
            color: inherit;

            @include link-states() {
                color: color('grey', 'default');
                text-decoration: underline;
            }
        }
    }

    #login_error {
        margin-bottom: rem(30px);
        padding: rem(halve($base-gutter));
        color: alert('error', 'message');
        background-color: alert('error');
        border: 1px solid alert('error', 'border');
        box-shadow: none;
    }
}

.wp-core-ui {
    .button-primary {
        text-shadow: none;
        background-color: color('brown', 'dark');
        border: 0;
        border-radius: 0;
        box-shadow: none;

        @include link-states() {
            background-color: color('brown', 'light');
            border: 0;
            box-shadow: none;
        }
    }
}
